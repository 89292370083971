import React from "react";
import styled, { useTheme } from "styled-components";
import { Link } from "./Link";
import { AnimatePresence, motion } from "framer-motion";
import { Search } from "../search/Search";

const Container = styled.div`
    padding: ${({ theme }) => theme.spacer};
    display: flex;

    gap: ${({ theme }) => theme.spacer};
`;

const HeadLink = styled(Link)`
    font-weight: bold;
    text-decoration: none;
`;

const BreadCrumbLink = styled(Link)`
    font-weight: normal;
    text-decoration: none;
    text-wrap: nowrap;
    color: ${({ theme }) => theme.pageFontColor};
`;

const Button = styled.button`
    background-color: ${({ theme }) => theme.buttonBackgroundColor};
    color: ${({ theme }) => theme.buttonFontColor};
    border: none;
    padding: ${({ theme }) => theme.spacer};
    border-radius: ${({ theme }) => theme.spacer};
    cursor: pointer;
`;

const Space = styled.div`
    flex-grow: 1;
`;

export interface Breadcrumb {
    title: string;
    url: string;
}

interface Props {
    darkMode: boolean;
    setDarkMode: (value: boolean) => void;
    breadcrumbs: Breadcrumb[];
}

export default function Header({ darkMode, setDarkMode, breadcrumbs }: Props) {
    const theme = useTheme();
    const animProps = {
        initial: { x: `-${theme.spacer}`, opacity: 0 },
        animate: { x: 0, opacity: 1 },
        transition: { duration: 1 },
        exit: { x: `${theme.spacer}`, opacity: 0 },
    };
    return (
        <Container>
            <AnimatePresence initial={false} mode="popLayout">
                <motion.div key="head-link" {...animProps}>
                    <HeadLink to="/">Aïkidex</HeadLink>
                </motion.div>
                {breadcrumbs.map((breadcrumb) => (
                    <motion.div key={breadcrumb.title} {...animProps}>
                        <BreadCrumbLink to={breadcrumb.url}>
                            {breadcrumb.title}
                        </BreadCrumbLink>
                    </motion.div>
                ))}
            </AnimatePresence>
            <Space />
            <Button onClick={() => setDarkMode(!darkMode)}>
                {darkMode ? "Light" : "Dark"}
            </Button>
        </Container>
    );
}
