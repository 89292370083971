interface ThemeType {
    pageBackgroundColor: string;
    pageFontFamily: string;
    pageFontSize: string;
    pageFontColor: string;
    pageTransition: string;

    linkColor: string;

    inputBackgroundColor: string;
    inputFontColor: string;
    inputBorderColor: string;
    inputFontSize: string;
    inputActiveBorderColor: string;
    inputActiveFontColor: string;

    buttonFontColor: string;
    buttonBackgroundColor: string;
    buttonBorderColor: string;

    spacer: string;

    searchColor: string;
    searchFieldBackgroundColor: string;
    searchFieldBorderTextColor: string;
    searchResultBackgroundColor: string;
    searchResultSeparatorColor: string;
    searchResultHighlightBackgroundColor: string;
    searchResultHighlightTextColor: string;
}

const palette = {
    colors: {
        primary: "#ff3245",
        secondary: "#6c757d",
        white: "#fcfcfc",
        light: "#ececec",
        dark: "#313333",
        black: "#1b1b1b",
    },
};

const sansSerif = "-system-ui, 'Roboto', Arial, sans-serif";

const transition = "background-color 1s ease 0s, color 1s ease 0s";
//const transition = "none";

const fontSizeNormal = "1.1rem";

const spacerNormal = "0.5rem";

const lightTheme: ThemeType = {
    pageBackgroundColor: palette.colors.light,
    pageFontFamily: sansSerif,
    pageFontSize: fontSizeNormal,
    pageFontColor: palette.colors.dark,
    pageTransition: transition,

    linkColor: palette.colors.primary,

    inputBackgroundColor: palette.colors.white,
    inputFontColor: palette.colors.secondary,
    inputBorderColor: palette.colors.secondary,
    inputFontSize: fontSizeNormal,
    inputActiveBorderColor: palette.colors.primary,
    inputActiveFontColor: palette.colors.black,

    buttonBackgroundColor: palette.colors.primary,
    buttonFontColor: palette.colors.white,
    buttonBorderColor: palette.colors.primary,

    searchColor: palette.colors.primary,
    searchFieldBackgroundColor: palette.colors.white,
    searchFieldBorderTextColor: palette.colors.secondary,
    searchResultBackgroundColor: palette.colors.white,
    searchResultSeparatorColor: palette.colors.secondary,
    searchResultHighlightBackgroundColor: palette.colors.primary,
    searchResultHighlightTextColor: palette.colors.white,

    spacer: spacerNormal,
};

const darkTheme: ThemeType = {
    pageBackgroundColor: palette.colors.dark,
    pageFontFamily: sansSerif,
    pageFontSize: fontSizeNormal,
    pageFontColor: palette.colors.light,
    pageTransition: transition,

    linkColor: palette.colors.primary,

    inputBackgroundColor: palette.colors.black,
    inputFontColor: palette.colors.secondary,
    inputBorderColor: palette.colors.secondary,
    inputFontSize: fontSizeNormal,
    inputActiveBorderColor: palette.colors.primary,
    inputActiveFontColor: palette.colors.white,

    buttonBackgroundColor: palette.colors.primary,
    buttonFontColor: palette.colors.white,
    buttonBorderColor: palette.colors.primary,

    searchColor: palette.colors.primary,
    searchFieldBackgroundColor: palette.colors.black,
    searchFieldBorderTextColor: palette.colors.light,
    searchResultBackgroundColor: palette.colors.black,
    searchResultSeparatorColor: palette.colors.secondary,
    searchResultHighlightBackgroundColor: palette.colors.primary,
    searchResultHighlightTextColor: palette.colors.white,

    spacer: spacerNormal,
};

export { lightTheme, darkTheme, ThemeType };
