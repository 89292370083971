import React from "react";
import styled from "styled-components";
import { Link } from "./Link";
import { ExternalLink } from "./ExternalLink";

const Container = styled.footer`
    padding: ${({ theme }) => theme.spacer};
`;

export default function Footer() {
    return (
        <Container>
            Ce site est développé par{" "}
            <ExternalLink href="https://alexandre.salome.fr">
                Alexandre Salomé
            </ExternalLink>
        </Container>
    );
}
