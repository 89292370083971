import Header, { Breadcrumb } from "../components/Header";
import React, { createContext, useState } from "react";
import Footer from "../components/Footer";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import { darkTheme, lightTheme } from "../theme/Theme";
import useDarkMode from "../theme/useDarkMode";
import { AnimatePresence, motion } from "framer-motion";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

interface BreadcrumbState {
    data: Breadcrumb[];
    set: (value: Breadcrumb[]) => void;
}

const initial = {
    data: [],
    set: () => {
        throw new Error("BreadcrumbContext not initialized");
    },
};

const BreadcrumbContext = createContext<BreadcrumbState>(initial);

const GlobalStyle = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        font-family: ${({ theme }) => theme.pageFontFamily};
        background-color: ${({ theme }) => theme.pageBackgroundColor};
        color: ${({ theme }) => theme.pageFontColor};
        font-size: ${({ theme }) => theme.pageFontSize};
        transition: ${({ theme }) => theme.pageTransition};
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;

const MainWrapper = styled(motion.main)`
    flex-grow: 1;
    position: relative;
    overflow-y: hidden;
`;

const Main = styled(motion.div)``;

const Page = styled.div`
    max-width: 640px;
    min-width: 320px;
    padding: ${({ theme }) => theme.spacer};
    margin: 0 auto;
`;

interface Props {
    path: string;
    children: React.ReactNode;
}

const usePageKey = (path: string): string => {
    let [pos, setPos] = useState(0);
    const [lastPath, setLastPath] = useState(path);
    if (lastPath !== path) {
        pos++;
        setLastPath(path);
        setPos(pos);
    }

    return pos.toString(10);
};

export default function Layout(props: Props) {
    const { children, path } = props;
    const [darkMode, setDarkMode] = useDarkMode();
    const [data, set] = useState([] as Breadcrumb[]);
    const key = usePageKey(path);
    const theme = darkMode ? darkTheme : lightTheme;
    const animProps = {
        initial: { x: `calc(${theme.spacer} * -10)`, opacity: 0 },
        animate: { x: 0, opacity: 1 },
        transition: { duration: 1, type: "linear" },
        exit: { x: `calc(${theme.spacer} * 10)`, opacity: 0 },
    };

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <BreadcrumbContext.Provider value={{ data, set }}>
                <Container>
                    <Header
                        darkMode={darkMode}
                        setDarkMode={setDarkMode}
                        breadcrumbs={data}
                    />
                    <MainWrapper>
                        <SimpleBar style={{ maxHeight: "100%" }}>
                            <AnimatePresence initial={false} mode="popLayout">
                                <Main key={key} {...animProps}>
                                    <Page>{children}</Page>
                                </Main>
                            </AnimatePresence>
                        </SimpleBar>
                    </MainWrapper>
                    <Footer />
                </Container>
            </BreadcrumbContext.Provider>
        </ThemeProvider>
    );
}

export { BreadcrumbContext };
