import React from "react";
import { Link as GatsbyLink } from "gatsby";
import styled from "styled-components";

interface Props {
    className?: string;
    to: string;
    children: React.ReactNode;
}

const Container = styled(GatsbyLink)`
    color: ${({ theme }) => theme.linkColor};
    transition: ${({ theme }) => theme.pageTransition};
`;

export function Link({ className, children, to }: Props) {
    return (
        <Container className={className} to={to}>
            {children}
        </Container>
    );
}
