import React from "react";
import styled from "styled-components";

interface Props {
    className?: string;
    href: string;
    children: React.ReactNode;
}

const Container = styled.a`
    color: ${({ theme }) => theme.linkColor};
`;

export function ExternalLink({ className, children, href }: Props) {
    return (
        <Container className={className} href={href}>
            {children}
        </Container>
    );
}
