import React from "react";

const isBrowser = typeof window !== "undefined";

export default function useDarkMode(): [boolean, (value: boolean) => void] {
    // Local storage
    const localStorage = isBrowser ? window.localStorage : undefined;
    let localDarkMode = localStorage?.getItem("dark_mode");
    let defaultDarkMode: boolean;

    if (isBrowser && localDarkMode === undefined) {
        const browserMatch = window?.matchMedia?.(
            "(prefers-color-scheme: dark)",
        );
        if (browserMatch) {
            defaultDarkMode = browserMatch.matches;
        } else {
            defaultDarkMode = false;
        }
    } else {
        defaultDarkMode = localDarkMode === "dark";
    }

    const [darkMode, doSetDarkMode] = React.useState(defaultDarkMode);

    const setDarkMode = (value: boolean) => {
        localStorage?.setItem("dark_mode", value ? "dark" : "light");
        doSetDarkMode(value);
    };

    return [darkMode, setDarkMode];
}
